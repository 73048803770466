<template>
  <div id="home-view">
    <!-- <div class="page-wrapper">
        <div class="header-container header-style-1"></div>
      </div> -->
    <div class="container home-slider-main" id="home-slider">
      <b-carousel
        v-if="slides.length != 0"
        id="carousel-1"
        :interval="slide_interval"
        controls
        no-hover-pause
        indicators
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
        @input="onSlideChange"
      >
        <div v-for="(slide_data, index) in slides">
          <b-carousel-slide
            :img-src="slide_data.web_slider"
            @error="handleMoveNext()"
            v-if="slide_data.file_type == 'Image'"
          ></b-carousel-slide>
          <b-carousel-slide v-if="slide_data.file_type == 'Video'">
            <video
              :id="'slide-' + index"
              @ended="index === slide_index && handleMoveNext()"
              @error="index === slide_index && handleMoveNext()"
              muted
              autoplay
              class="video-cover"
            >
              <source :src="slide_data.web_slider" type="video/mp4" />
            </video>
          </b-carousel-slide>
        </div>
      </b-carousel>
    </div>
    <div class="container my-4">
      <!-- Wizard Section -->
      <section class="wizard-section">
        <img src="@/assets/site/images/wizard.png" alt="wizard image" />
        <div @click="showWizard()" class="button">
          Start Gifting
        </div>
        <p class="text">
          The World's First <span class="blue">AI</span> Gift Wizard
        </p>
      </section>

      <!-- Trending Gifts Section -->
      <section v-if="trendingProducts.length > 0" class="best-sellers-section">
        <h3 class="best-sellers-section-title">
          TRENDING GIFTS
        </h3>
        <div class="best-sellers">
          <div
            @click="ViewProduct(bestSeller)"
            class="best-seller"
            v-for="bestSeller in trendingProducts"
          >
            <div class="image-wrapper">
              <img
                v-if="bestSeller.imageurl"
                class="product-image-photo lazyload"
                :src="bestSeller.imageurl"
                @error="() => (bestSeller.imageurl = null)"
                data-src=""
                loading="lazy"
                alt="Best Seller Product Image"
              />
              <img
                v-else
                class="product-image-photo lazyload"
                src="@/assets/site/images/noimage.jpeg"
                data-src=""
                loading="lazy"
                alt="Best Seller Product Image"
              />
              <div class="icons">
                <div
                  @click.stop="savetoFavourite(bestSeller)"
                  title="Add to Favourite"
                  class="icon"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
                    />
                  </svg>
                </div>
                <div
                  @click.stop="savetoWishlist(bestSeller)"
                  title="Add to Wish List"
                  class="icon"
                >
                  <svg width="20" height="20" viewBox="0 0 512 512">
                    <path
                      d="M190.5 68.8L225.3 128l-1.3 0-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40l2.2 0c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40L32 128c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l448 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-41.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88l-2.2 0c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0L152 0C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40l-72 0-1.3 0 34.8-59.2C329.1 55.9 342.9 48 357.8 48l2.2 0c22.1 0 40 17.9 40 40zM32 288l0 176c0 26.5 21.5 48 48 48l144 0 0-224L32 288zM288 512l144 0c26.5 0 48-21.5 48-48l0-176-192 0 0 224z"
                    />
                  </svg>
                </div>
                <div
                  @click.stop="compareProduct(bestSeller)"
                  title="Add to Compare"
                  class="icon"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.1"
                      d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
                      fill="#fe3c00"
                    />
                    <path
                      opacity="0.1"
                      d="M21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18C15 16.3431 16.3431 15 18 15C19.6569 15 21 16.3431 21 18Z"
                      fill="#fe3c00"
                    />
                    <path
                      d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
                      stroke="#fe3c00"
                      stroke-width="2"
                    />
                    <path
                      d="M21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18C15 16.3431 16.3431 15 18 15C19.6569 15 21 16.3431 21 18Z"
                      stroke="#fe3c00"
                      stroke-width="2"
                    />
                    <path
                      d="M15 3L12.0605 5.93945V5.93945C12.0271 5.97289 12.0271 6.02711 12.0605 6.06055V6.06055L15 9"
                      stroke="#fe3c00"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 21L11.9473 18.0527V18.0527C11.9764 18.0236 11.9764 17.9764 11.9473 17.9473V17.9473L9 15"
                      stroke="#fe3c00"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 6C14.8284 6 16.2426 6 17.1213 6.87868C18 7.75736 18 9.17157 18 12V15"
                      stroke="#fe3c00"
                      stroke-width="2"
                    />
                    <path
                      d="M12 18C9.17157 18 7.75736 18 6.87868 17.1213C6 16.2426 6 14.8284 6 12L6 9"
                      stroke="#fe3c00"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div
                  @click.stop="ViewProduct(bestSeller)"
                  title="Quick View"
                  class="icon"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path
                      d="M572.5 241.4C518.3 135.6 410.9 64 288 64S57.7 135.6 3.5 241.4a32.4 32.4 0 0 0 0 29.2C57.7 376.4 165.1 448 288 448s230.3-71.6 284.5-177.4a32.4 32.4 0 0 0 0-29.2zM288 400a144 144 0 1 1 144-144 143.9 143.9 0 0 1 -144 144zm0-240a95.3 95.3 0 0 0 -25.3 3.8 47.9 47.9 0 0 1 -66.9 66.9A95.8 95.8 0 1 0 288 160z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="product-line"></div>
            <a
              data-post=""
              @click.stop="selectOccasion(bestSeller)"
              class="trending-product-wishlist"
              data-action="add-to-wishlist"
              title="Add to Favourite"
            >
              <svg width="20" height="20" viewBox="0 0 512 512">
                <path
                  d="M190.5 68.8L225.3 128l-1.3 0-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40l2.2 0c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40L32 128c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l448 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-41.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88l-2.2 0c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0L152 0C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40l-72 0-1.3 0 34.8-59.2C329.1 55.9 342.9 48 357.8 48l2.2 0c22.1 0 40 17.9 40 40zM32 288l0 176c0 26.5 21.5 48 48 48l144 0 0-224L32 288zM288 512l144 0c26.5 0 48-21.5 48-48l0-176-192 0 0 224z"
                />
              </svg>
            </a>
            <div class="content">
              <p class="best-seller-title">
                {{ bestSeller.productname }}
              </p>
              <div class="best-seller-price">
                {{ bestSeller.saleprice["@currency"] }}
                {{ bestSeller.saleprice["#text"] }}
              </div>
              <div class="best-seller-rating">
                <svg width="12" height="12" viewBox="0 0 576 512">
                  <path
                    d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                  />
                </svg>
                <svg width="12" height="12" viewBox="0 0 576 512">
                  <path
                    d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                  />
                </svg>
                <svg width="12" height="12" viewBox="0 0 576 512">
                  <path
                    d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                  />
                </svg>
                <svg width="12" height="12" viewBox="0 0 576 512">
                  <path
                    d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                  />
                </svg>
                <svg width="12" height="12" viewBox="0 0 576 512">
                  <path
                    d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Customer Slider Section -->
      <section class="home-customer-slider-wrapper">
        <div
          class="home-customer-slider"
          :style="{ animationDuration: customerSliders.length * 5 + 's' }"
        >
          <img
            v-for="slider in customerSliders"
            :src="slider.customer_slider"
            alt="customer slider"
          />
        </div>
        <div
          class="home-customer-slider"
          :style="{ animationDuration: customerSliders.length * 5 + 's' }"
        >
          <img
            v-for="slider in customerSliders"
            :src="slider.customer_slider"
            alt="customer slider"
          />
        </div>
      </section>
      <div
        id="myModal"
        ref="modal"
        class="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content p-5 custom-modal">
            <div class="row d-flex justify-content-end">
              <div class="col-1 cursor-pointer" @click="closeloginModal">
                <img
                  src="@/assets/site/images/close-icon.svg"
                  width="40"
                  height="40"
                  class="img-fluid modal-close"
                  alt=""
                />
              </div>
            </div>

            <div class="row text-center">
              <div class="col-md-6 my-3">
                <img
                  src="@/assets/site/images/new/banner.jpg"
                  class="img-fluid"
                  alt=""
                />
                <div class="row mt-4 d-flex justify-content-center">
                  <div class="col-2">
                    <div
                      class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                    >
                      <img
                        src="@/assets/site/images/new/laptop.png"
                        width="40"
                        height="40"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-2">
                    <div
                      class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                    >
                      <img
                        src="@/assets/site/images/new/shoes.jpg"
                        width="40"
                        height="40"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-2">
                    <div
                      class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                    >
                      <img
                        src="@/assets/site/images/new/pendant.jpg"
                        width="40"
                        height="40"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-2">
                    <div
                      class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                    >
                      <img
                        src="@/assets/site/images/new/smart-watch.webp"
                        width="40"
                        height="40"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-2">
                    <div
                      class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                    >
                      <img
                        src="@/assets/site/images/new/watch.webp"
                        width="40"
                        height="40"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <h3 class="py-5 modal-head">FIND THE PERFECT GIFT</h3>
              </div>
              <div class="col-md-6 my-3">
                <h3>
                  <b>Unlock Govava True Power</b>
                </h3>

                <!-- <p class="my-4 text-left" style="font-size: 14px">
                    When you sign-up, you will be in on a Gift buying journey
                    unlike anything you have EVER experienced
                  </p> -->

                <p class="my-4 text-left" style="font-size: 14px">
                  Sign up to embark on a unique gifting experience unlike any
                  other.
                </p>
                <!-- <router-link to="/login" > -->
                <button class="btn btn-info1 my-4">
                  <a href="/login">Sign Up</a>
                </button>
                <!-- </router-link> -->

                <ul id="modal_ul" class="my-4 text-left">
                  <li>Access to over 8000+ Retail stores</li>
                  <li>Valid coupons on almost every item instantly</li>
                  <li>No more guessing when trying to find gifts</li>
                  <li>Shopping for gifts for your pets</li>
                  <li>Gift-Chat in real time using the app</li>
                  <li>Save Gifts for family and friends in Gift-Cart</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Footer -->
    <wizard-view
      v-bind:wizard_data="wizard_data"
      v-bind:modal_id="modal_id"
    ></wizard-view>
    <div>
      <occasion-modal v-on:savetoWishlist="savetoWishlist"></occasion-modal>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel2";
import Vue from "vue";
import BackToTop from "vue-backtotop";
import WizardView from "@/views/site/wizard/WizardView";
import OccasionModal from "@/views/site/OccasionModal";

Vue.use(BackToTop);
export default {
  name: "HomeView",
  components: {
    carousel,
    OccasionModal,
    WizardView,
  },
  data() {
    return {
      // Wizard Related Data
      menu: null,
      menus: [],
      wizard_data: null,
      gender: null,
      modal_id: null,

      userData: {},
      loader: false,
      errors: [],
      pages: [],
      products: [],
      trendingProducts: [],
      friend: null,
      slides: [],
      blogs: [],
      customerSliders: [],
      siteBanners: [],
      showslider: false,
      description: "",
      slide_interval: 5000,
      slide_index: 0,
      occasion_id: null,
      wishlist_product: null,
    };
  },
  mounted() {
    var token = localStorage.getItem("userData");
    this.userData = token;
  },
  created() {
    const token = localStorage.getItem("userData");
    this.getMenuLists();
    this.getCategoryList();
    this.getPages();
    this.getSliderImages();
    this.getBannerImages();
    this.getCustomerSliders();
    this.getBlogs();
    if (token) this.getTrendingProducts();
  },
  methods: {
    // Wizard Related Methods
    getCategoryList() {
      var fetch_url =
        process.env.VUE_APP_URL + "customer/category/categoryList";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.categories = data.categories;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getMenuLists() {
      var fetch_url = process.env.VUE_APP_URL + "customer/menu/menuList";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.menus = data.menus;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    setUserDatas() {
      var loc_UserData = localStorage.getItem("userData");
      // if (loc_UserData.user.name == null) {
      //         localStorage.clear();
      //         alert("Please log in to continue");
      // }
      // console.log(loc_UserData);
      // if (loc_UserData) {
      // this.userData = JSON.parse(loc_UserData);
      // if (this.userData.user.name) {
      //   this.userName = this.userData.user.name;
      // }
      // }
    },
    showWizard() {
      // if (loc_UserData == null) {
      //   alert("Please log in to continue");
      // } else {
      // this.menu = menu;
      const token = localStorage.getItem("userData");
      const wizardUsage = localStorage.getItem("wizardUsage") ?? 0;

      if (wizardUsage >= 3 && !token) $("#myModal").modal("show");
      else {
        this.modal_id = "menubar_wizard_modal";
        this.$bvModal.show("menubar_wizard_modal");
      }

      // }
    },
    onSlideChange(slideNumber) {
      this.slide_interval = 5000;
      if (this.slides[slideNumber].file_type == "Video") {
        const videoEl = document.querySelector(`#slide-${slideNumber}`);
        if (videoEl) {
          videoEl.currentTime = 0;
          videoEl.play();
        }
        this.slide_interval = 60000000;
      }
      this.slide_index = slideNumber;
    },
    handleMoveNext() {
      this.slide_interval = 1;
    },
    getCustomerSliders() {
      this.errors = [];
      let method_type = "";
      let fetch_url = "";
      method_type = "get";
      fetch_url =
        process.env.VUE_APP_URL + "customer/page/getCustomerSliderImages";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.customerSliders = data.slider;
        });
    },
    getTrendingProducts() {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var country_code = localStorage.getItem("countryCode");
      var countryCode = JSON.parse(country_code);
      var fetch_url =
        process.env.VUE_APP_URL + "customer/products/getBestSellers";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.trendingProducts = data.response.items;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getProducts() {
      this.errors = [];
      //this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      if (token == null) {
        alert("Please log in to continue");
      } else {
        var api_token = userdata_array.token;
        var user_id = userdata_array.user.id;
        let method_type = "";
        let fetch_url = "";
        method_type = "post";
        fetch_url = process.env.VUE_APP_URL + "customer/products/getProducts";
        fetch(fetch_url, {
          method: method_type,
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify({
            user_id: user_id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            this.products = data.response.items;
          });
      }
    },
    getPages() {
      this.errors = [];
      //this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      //  var api_token = userdata_array.token;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/page/getPages";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.pages = data.page;
        });
    },
    getBlogs() {
      this.errors = [];
      //this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      //  var api_token = userdata_array.token;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/blog/getBlogs";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.blogs = data.blog;
        });
    },
    getSliderImages() {
      this.errors = [];
      let method_type = "";
      let fetch_url = "";
      method_type = "get";
      fetch_url = process.env.VUE_APP_URL + "customer/page/getSliderImages";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.slides = data.slider;
          if (this.slides.length != 0) {
            this.showslider = true;
            if (data.slider[0].file_type === "Video") {
              this.onSlideChange(0);
              this.slide_interval = 60000000;
            }
          }
        });
    },
    getBannerImages() {
      this.errors = [];
      //this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      //  var api_token = userdata_array.token;
      let method_type = "";
      let fetch_url = "";
      method_type = "get";
      fetch_url = process.env.VUE_APP_URL + "customer/page/getBannerImages";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.siteBanners = data.banner;
          // if(this.slides.length!=0){
          //     this.showslider=true;
          // }
        });
    },
    selectOccasion(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        //alert("Please log in to continue");
        $("#myModal").modal("show");
      } else {
        this.wishlist_product = product;
        this.$bvModal.show("occasion-modal");
      }
    },
    savetoWishlist(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        //alert("Please log in to continue");
        $("#myModal").modal("show");
      } else {
        //Save or update User Details
        this.$bvModal.hide("occasion-modal");
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);

        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/userwishlist";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: product.productId,
            //product_name:product.productName,
            product: product,
            //price:product.price.text,
            user_id: user_id,
            wishlist_type: "User",
            occasion_id: product.id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;

              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });

        var fetch_url =
          process.env.VUE_APP_URL +
          "customer/products/saveAffilativeNetwork/" +
          this.productId;
        fetch(fetch_url, {
          method: "get",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
        })
          .then((res) => res.json())
          .then((data) => {});

        this.wishlist_product = null;
      }
    },
    compareProduct(bestSeller) {
      //Save or update User Details
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        //alert("Please log in to continue");
        $("#myModal").modal("show");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/compare";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: bestSeller.productId,
            //product_name:product.productName,
            product: bestSeller,
            //price:product.price.text,
            user_id: user_id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    checkWizardProducts() {
      var loc_wizardProducts = localStorage.getItem("wizardProducts");
      // var loc_selcetedFriend = localStorage.getItem("selcetedFriend");
      if (loc_wizardProducts == null) {
        var wizardproducts = JSON.parse(loc_wizardProducts);
        this.products = wizardproducts.items;
        localStorage.removeItem("wizardProducts");
      } else {
        this.getProducts();
      }
    },
    image(url) {
      var url = url;
      var img_url = url.substring(5);
      return "http" + img_url;
    },
    ViewProduct(bestSeller) {
      this.$store.state.wizard_type = "User";
      const id = Math.random()
        .toString(36)
        .slice(2);
      this.$router.push({
        path: "/single-product/product/" + id,
        query: { singleproduct: bestSeller },
      });
    },
    ViewanotherProduct(product) {
      this.$store.state.wizard_type = "User";
      const id = Math.random()
        .toString(36)
        .slice(2);
      this.$router.push({
        path: "/single-product/product/" + id,
        query: {
          singleproduct: product,
        },
      });
    },
    savetoFavourite(bestSeller) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        //alert("Please log in to continue");
        $("#myModal").modal("show");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/favourite";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: bestSeller.productId,
            product: bestSeller,
            user_id: user_id,
            user_action: "AddToFav",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;

              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.$swal.fire("Error", data.message, "error");
            } else {
              // this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
      //Save or update User Details
    },
    closeloginModal() {
      $("#myModal").modal("hide");
    },
  },
};
</script>

<style>
#modal_ul {
  list-style: none;
}
#modal_ul li {
  margin-top: 12px;
  font-size: 14px;
}
#modal_ul li:before {
  content: "✓";
  padding-right: 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.modal-head {
  color: #ff0000 !important;
  font-weight: bold !important;
}
.btn-info1 {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}
.btn-info1 a {
  color: #fff !important;
  text-transform: capitalize !important;
}
.custom-modal {
  border-radius: 50px !important;
  border: 2px solid #ffc000 !important;
}
.category-holder1 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
}

/* Main Slider Section Styles */
.home-slider-main {
  max-width: 55% !important;
}

.home-slider-main *:is(video, img, .carousel-item) {
  width: 100% !important;
  max-width: 100% !important;
  height: 480px !important;
}

.home-slider-main .carousel-caption {
  left: 0;
  right: 0;
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .home-slider-main {
    max-width: 80% !important;
  }

  .home-slider-main *:is(video, img, .carousel-item) {
    height: 320px !important;
  }
}

@media screen and (max-width: 640px) {
  .home-slider-main {
    max-width: 95% !important;
  }

  .home-slider-main *:is(video, img, .carousel-item) {
    height: 240px !important;
  }
}

/* Wizard Section Styles */
.wizard-section {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.wizard-section img {
  display: block;
  width: 72px;
  height: auto;
}

.wizard-section .button {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  padding: 8px 24px;
  background: #fe3c00;
  color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.wizard-section .text {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #000000;
}

.wizard-section .text .blue {
  font-size: inherit;
  color: #35cee6 !important;
}

/* Best Sellers Section Styles */
.best-sellers-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.best-sellers-section .best-sellers-section-title {
  font-size: 24px;
  font-weight: 600;
  color: #fe3c00;
  margin-bottom: 12px;
}

.best-sellers-empty-state {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
}

.best-sellers-section .best-sellers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.best-sellers-section .best-sellers .best-seller {
  position: relative;
  padding: 64px 24px;
  border-radius: 4px;
  border: 1px solid #dadada;
  overflow: hidden;
  cursor: pointer;
}

.best-sellers-section .best-sellers .best-seller:hover {
  border: 1px solid #fe3c00;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper {
  position: relative;
  max-width: 100%;
  height: 240px;
  object-fit: cover;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.best-sellers-section .best-sellers .best-seller:hover .image-wrapper img {
  opacity: 0.25;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper .icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.best-sellers-section .best-sellers .best-seller:hover .image-wrapper .icons {
  display: flex;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper .icons .icon {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  fill: #fe3c00;
}

.best-sellers-section
  .best-sellers
  .best-seller
  .image-wrapper
  .icons
  .icon
  svg {
  width: 24px;
}

.best-sellers-section .best-sellers .best-seller:hover .image-wrapper .icons {
  display: flex;
}

.best-sellers-section .best-sellers .best-seller .actions {
  position: absolute;
  top: 0;
  left: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.best-sellers-section .best-sellers .best-seller .actions .action {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transform: rotateX(90deg);
  background: #ffffff;
  color: #fe3c00;
  transform-origin: top;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.2);
}

.best-sellers-section .best-sellers .best-seller:hover .action {
  transform: rotateX(0);
}

.best-sellers-section .best-sellers .best-seller .actions .action:nth-child(2) {
  transition-delay: rotate 0.1s;
}

.best-sellers-section .best-sellers .best-seller .actions .action:nth-child(3) {
  transition-delay: rotate 0.2s;
}

.best-sellers-section .best-sellers .best-seller .actions .action:last-child {
  transition-delay: rotate 0.3s;
}

.best-sellers-section .best-sellers .best-seller .action:hover {
  background: #fe3c00;
  fill: #ffffff;
}

.best-sellers-section .best-sellers .best-seller .trending-product-wishlist {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  fill: #fe3c00;
  border: 1px solid #fe3c00;
  padding: 4px;
  border-radius: 4px;
}

.best-sellers-section .best-sellers .best-seller .product-line {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    #dadada 42%,
    #fe3c00 42%,
    #fe3c00 58%,
    #dadada 58%
  );
}

.best-sellers-section .best-sellers .best-seller .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.best-sellers-section .best-sellers .best-seller .content .best-seller-rating {
  display: flex;
  align-items: center;
  gap: 4px;
  fill: #ffc000;
}

.best-sellers-section .best-sellers .best-seller .content .best-seller-title {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.3;
  color: #666666;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.best-sellers-section .best-sellers .best-seller .content .best-seller-price {
  color: #fe3c00;
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 1280px) {
  .best-sellers-section .best-sellers {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
}

@media screen and (max-width: 992px) {
  .best-sellers-section .best-sellers {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 640px) {
  .best-sellers-section .best-sellers {
    grid-template-columns: 1fr;
  }

  .best-sellers-section .best-sellers .best-seller .actions .action {
    transform: rotateX(0);
  }
}

/* Customer Slider Section Styles */
.home-customer-slider-wrapper {
  position: relative;
  margin: 40px 0;
  padding: 30px 0px;
  white-space: nowrap;
  overflow: hidden;
}

.home-customer-slider {
  width: max-content;
  display: inline-block;
  animation-name: slides;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.home-customer-slider img {
  height: 60px;
  margin: 0 10px;
}

@media screen and (max-width: 1280px) {
  .home-customer-slider img {
    height: 40px;
  }
}

@keyframes slides {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
